import {post,put,get} from '../http';
import { getStorage } from "@/utils/tools";

export async function getQuotaStatusApi(params){
    return post(`/business/dictionary/findDicLt`, params,true,50000,"ap2");
} 

export async function getEndorsementApplyListApi(params){
    return post(`/scb/v1/endorsement/apply/list`, params,true,50000,"ap2");
} 
export async function getEndorsementgetDetailApi(params){
    return post(`/scb/v1/issue/getDetail`, params,true,50000,"ap2");
}
export async function getEndorsementApplyApi(params){
    return post(`/scb/v1/endorsement/apply`, params,true,50000,"ap2");
}
export async function getTicketVoucherQueryApi(params){
    return post(`/scb/v1/issue/ticket/voucher/query`, params,true,50000,"ap2");
}
export async function getEndorsementscbGetDetailApi(params){
    return post(`/scb/v1/endorsement/getDetail`, params,true,50000,"ap2");
}
export async function getEndorsementApplyAuditApi(params){
    return post(`/scb/v1/endorsement/apply/audit`, params,true,50000,"ap2");
}
export async function getEndorsementRevokeApplyApi(params){
    return post(`/scb/v1/endorsement/revoke/apply`, params,true,50000,"ap2");
}
export async function getEndorsementRevokeReplyListApi(params){
    return post(`/scb/v1/endorsement/reply/list`, params,true,50000,"ap2");
}
export async function getEndorsementRevokeReplyAuditApi(params){
    return post(`/scb/v1/endorsement/revoke/apply/audit`, params,true,50000,"ap2");
}
export async function getEndorsementReplyApplyApi(params){
    return post(`/scb/v1/endorsement/reply/apply`, params,true,50000,"ap2");
}
export async function getEndorsementReplyApplyAuditApi(params){
    return post(`/scb/v1/endorsement/reply/apply/audit`, params,true,50000,"ap2");
}
export async function getEndorsementListtApi(params){
    return post(`/scb/v1/endorsement/list`, params,true,50000,"ap2");
}
export async function getEndorsementInfoQueryApi(params){
    return post(`/scb/v1/endorsement/info/query`, params,true,50000,"ap2");
}
