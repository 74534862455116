import {post, put, get} from '../http'

const scfBusinessApi = {

	//借款方授信添加
	addBorrowerCreditApi(params) {
		return post('/business/borrowerCredit/addBorrowerCredit', params);
	}, //借款方授信列表
	getborrowerCreditListApi(params) {
		return post('/business/borrowerCredit/getborrowerCreditPageList', params);
	}, //借款方授信详情
	getBorrowerCreditDetailApi(params) {
		return post('/business/borrowerCredit/getBorrowerCreditDetail', params);
	}, //借款方授信修改
	modifyBorrowerCreditApi(params) {
		return post('/business/borrowerCredit/modifyBorrowerCredit', params);
	}, //借款方授信禁用or启用
	modifyBorrowerStateApi(params) {
		return post('/business/borrowerCredit/modifyBorrowerState', params);
	}, //借款方授信禁用or启用
	forbiddenApi(params) {
		return post('/business/product/forbidden', params);
	}, //查所有项目
	getAllProjectByBusRoleApi(params) {
		return post('/business/enterpriseCredit/getAllProjectByBusRole', params);
	}, //获取全项目信息
	getAllProjectApi(params) {
		return post('/business/enterpriseCredit/getAllProject', params);
	}, //核心企业授信列表
	getenterpriseCreditListApi(params) {
		return post('/business/enterpriseCredit/getEnterpriseCreditPageList', params);
	}, //核心企业授信禁用
	modifyEnterpriseStateApi(params) {
		return post('/business/enterpriseCredit/modifyEnterpriseState', params);
	}, //核心企业授信添加
	addCreditApi(params) {
		return post('/business/enterpriseCredit/addCredit', params);
	}, //核心企业授信编辑
	modifyEnterpriseCreditApi(params) {
		return post('/business/enterpriseCredit/modifyEnterpriseCredit', params);
	}, //核心企业授信详情
	getEnterpriseCreditDetailApi(params) {
		return post('/business/enterpriseCredit/getEnterpriseCreditDetail', params);
	},

	//产品管理中根据项目名称查资方和核心企业信息
	getProductEnterpriseCreditDetailApi(params) {
		return post('/business/enterpriseCredit/getEnterpriseCreditDetail', params);
	}, //获取全项目信息
	getAllProjectSignConfigApi(params) {
		return post('/business/enterpriseCredit/getAllProject', params);
	}, //获取某项目下全产品信息
	getProjectProductsApi(params) {
		return post('/business/product/getProjectProducts', params);
	}, //产品授信添加
	addproductApi(params) {
		return post('/business/product/addproduct', params);
	}, //产品授信列表
	getproductListApi(params) {
		return post('/business/product/getProductPageList', params);
	}, //产品授信修改
	modifyProductApi(params) {
		return post('/business/product/modifyProduct', params);
	}, //产品授信详情
	getAllEnterpriseApi(params) {
		return post('/business/product/getproductDetail', params);
	}, //产品授信启用
	productEnableApi(params) {
		return post('/business/product/productEnable', params);
	}, //字典信息查询
	findDicLtApi(params) {
		return post('/business/dictionary/findDicLt', params, true, 50000, "ap2");
	}, //还款方式字典信息查询
	findRepayWayLtApi(params) {
		return post('/business/product/repayWay/findDicLt', params, true, 5000, "apc");
	}, //文件上传
	fileUploadApi(params) {
		return post('/business/resourcefile/upload', params, true, 5000, "apc");
	}, //下载文件流
	fileDownloadApi(params) {
		return post('/business/resourcefile/downloadFileToBase64', params, true, 50000, "apc");
	}, //资源文件删除
	fileDeleteApi(params) {
		return post('/business/resourcefile/delete', params, true, 5000, "apc");
	}, //资源文件删除
	resourceFiledeleteSApi(params) {
		return post('/business/resourcefile/delete', params);
	}, //公用接口-资源文件上传
	resourceFileUploadApi(params) {
		return post('/business/resourcefile/upload', params);
	}, //陕建批量导入：文件上传后得解析接口
	resourceBatchFileUploadApi(params) {
		return post('/business/ticket/publish/analysis/v1', params);
	}, //陕建：批量导入添加功能
	queryAddBatchApi(params) {
		return post('/business/ticket/publish/addBatch/v1', params);
	}, //合同模板分页列表
	contractTemplatefindPageListApi(params) {
		return post('/business/contracttemplate/findPageList', params);
	}, //合同模板详情
	contractTemplatefindDetailApi(params) {
		return post('/business/contracttemplate/findDetail', params);
	}, //合同模板新增
	contractTemplateAddApi(params) {
		return post('/business/contracttemplate/add', params);
	}, //合同模板上传
	contractTemplateUploadApi(params) {
		return post('/business/contracttemplate/analysis', params);
	}, //合同模板修改
	contractTemplateUpdateApi(params) {
		return post('/business/contracttemplate/update', params);
	}, //合同模板删除
	contractTemplateDeleteApi(params) {
		return post('/business/contracttemplate/delete', params);
	}, // 签约配置合同模板获取
	findContractTemplatesApi(params) {
		return post('/business/signconfig/findContractTemplates', params);
	}, // 签约配置分页列表
	findPageListApi(params) {
		return post('/business/signconfig/findPageList', params);
	}, // 签约配置新增
	signConfigAddApi(params) {
		return post('/business/signconfig/add', params);
	}, // 签约配置详情
	signConfigFindDetailApi(params) {
		return post('/business/signconfig/findDetail', params);
	}, // 签约配置修改
	signConfigUpdateApi(params) {
		return post('/business/signconfig/update', params);
	}, // 签约配置删除
	signConfigDeleteApi(params) {
		return post('/business/signconfig/delete', params);
	}, // 凭证配置分页列表
	findPageListcredentialsApi(params) {
		return post('/business/contract/voucher/sign/config/findPageList', params);
	}, //  凭证配置新增
	signConfigAddcredentialsApi(params) {
		return post('/business/contract/voucher/sign/config/add', params);
	}, //  凭证配置详情
	signConfigFindDetailcredentialsApi(params) {
		return post('/business/contract/voucher/sign/config/findDetail', params);
	}, // 凭证配置修改
	signConfigUpdatecredentialsApi(params) {
		return post('/business/contract/voucher/sign/config/update', params);
	}, // 凭证配置删除
	signConfigDeletecredentialsApi(params) {
		return post('/business/contract/voucher/sign/config/delete', params);
	}, // 凭证签约配置合同模板获取
	findContractTemplatescredentialsApi(params) {
		return post('/business/contracttemplate/list', params);
	}, //票付通
	queryListApi(params) {
		return post('/pft/api/statement/queryList', params);
	}, //富民
	listApi(params) {
		return post('/account/apiStatement/list', params);
	}, //富民
	statementIdApi(apiStatementId, params) {
		return post(`/account/apiStatement/${apiStatementId}`, params);
	}, //第三方2
	// thirdpartylogApi(params) {
	// 	return post('/statistics/thirdpartylog/getThirdpartyLogs', params);
	// },
	thirdpartylogApi(params) {
		return post('/ticket/statistics/thirdpartylog/getThirdpartyLogs', params);
	},


	//商票交易列表
	getPageListApi(params) {
		return post('/business/split/transfer/getPageList', params);
	}, //商票交易详情
	getDetailApi(params) {
		return post('/business/split/transfer/getDetail', params);
	}, //商票交易转让详情
	getTransferDetailApi(params) {
		return post('/business/split/transfer/getTransferDetail', params);
	}, //运营-票据背面
	getAdminTicketBackApi(params) {
		return post('/business/split/transfer/getAdminTicketBack', params);
	}, //借款方-票据背面
	getTicketBackApi(params) {
		return post('/business/split/transfer/getTicketBack', params);
	}, //转让-转让列表
	getTransferLtApi(params) {
		return post('/business/split/transfer/getTransferLt', params);
	}, //转让方列表信息查询
	getTransferorListApi(params) {
		return post('/business/transferor/getTransferorList', params);
	}, //转让新增
	addTransferApi(params) {
		return post('/business/split/transfer/addTransfer', params);
	}, //转让审核
	auditTransferApi(params) {
		return post('/business/split/transfer/auditTransfer', params);
	}, //转让重新提交
	reAddTransferApi(params) {
		return post('/business/split/transfer/reAddTransfer', params);
	}, //转让撤销提交
	revokeTransferApi(params) {
		return post('/business/split/transfer/revokeTransfer', params);
	}, //转让撤销重新提交
	reRevokeTransferApi(params) {
		return post('/business/split/transfer/reRevokeTransfer', params);
	}, //转让撤销提交审核
	auditRevokeTransferApi(params) {
		return post('/business/split/transfer/auditRevokeTransfer', params);
	}, //取消撤销转让
	cancelRevokeTransferApi(params) {
		return post('/business/split/transfer/cancelRevokeTransfer', params);
	}, //签收
	signTransferApi(params) {
		return post('/business/split/transfer/signTransfer', params);
	}, //签收审核
	auditSignTransferApi(params) {
		return post('/business/split/transfer/auditSignTransfer', params);
	},

	//字典查询批量
	dictionaryApi(params) {

		return post(`/business/dictionary?typeList=${params.typeList[0]},adsf`, params);
	},

	//查询审核历史
	auditHistoryApi(params) {
		return post(`/business/audit/auditHistory`, params);
	},

	//展示原票详情
	getTicketDetailApi(params) {
		return post(`/business/ticket/publish/getDetail`, params);
	},

	//待办进入所需接口参数IDS查询
	getAuditNeedIdsApi(params) {
		return post(`/business/split/transfer/getAuditNeedIds`, params);
	}, //首页待办事项查询
	todoQueryApi(params) {
		return post(`/business/audit/todoQuery`, params);
	}, //运营方首页额度查询
	getTotalAmountApi(params) {
		return post(`/business/ticketApply/getTotalAmount`, params);
	},

	//富民账户交易统计
	// getFmStatisticsApi(params) {
	// 	return post(`/statistics/fumin`, params);
	// },
	getFmStatisticsApi(params) {
		return post(`/account/statistics/fumin`, params);
	}, //用户统计
	// companyCountsApi(params) {
	// 	return post(`/statistics/companyCounts`, params);
	// },
	companyCountsApi(params) {
		return post(`/ticket/statistics/companyCounts`, params);
	}, //省级区域交易额统计
	// provincesAndCitiesAmountApi(params) {
	// 	return post(`/statistics/provincesAndCitiesAmount`, params);
	// },
	provincesAndCitiesAmountApi(params) {
		return post(`/ticket/statistics/provincesAndCitiesAmount`, params);
	}, //平台交易总额
	// busTomalAmountApi(params) {
	// 	return post(`/statistics/busTomalAmount`, params);
	// },
	busTomalAmountApi(params) {
		return post(`/ticket/statistics/busTomalAmount`, params);
	}, //实时平台交易额统计
	// mtRealAmountApi(params) {
	// 	return post(`/statistics/mtRealAmount`, params);
	// },
	mtRealAmountApi(params) {
		return post(`/ticket/statistics/mtRealAmount`, params);
	}, //京东渠道报价
	// queryQuoteApi(params) {
	// 	return post(`/statistics/draftList`, params);
	// },
	queryQuoteApi(params) {
		return post(`/ticket/statistics/draftList`, params);
	}, //富民渠道报价
	// fuminIcipApi(params) {
	// 	return post(`/statistics/fumin-icip`, params);
	// },
	fuminIcipApi(params) {
		return post(`/account/statistics/fumin-icip`, params);
	}, //统计面板-撮合交易额统计
	// actualValueApi(params) {
	// 	return post(`/statistics/draft/actualValue`, params);
	// },
	actualValueApi(params) {
		return post(`/ticket/statistics/draft/actualValue`, params);
	}, //富民秒贴交易额
	// dealAmountApi(params) {
	// 	return post(`/statistics/seconds/sticker/dealAmount`, params);
	// },
	dealAmountApi(params) {
		return post(`/ticket/statistics/seconds/sticker/dealAmount`, params);
	}, //线下票据登记总交易金额
	// ticketAmountApi(params) {
	// 	return post(`/statistics/offline/ticketAmount`, params);
	// },
	ticketAmountApi(params) {
		return post(`/ticket/statistics/offline/ticketAmount`, params);
	}, //区域票据价格
	// ticketPriceApi(params) {
	// 	return post(`/statistics/ticketPrice`, params);
	// },
	ticketPriceApi(params) {
		return post(`/ticket/statistics/ticketPrice`, params);
	},


	//首页发行统计
	publishStatisticsApi(params) {
		return post(`/business/ticket/publish/statistics/v1`, params);
	},


	//还款记录列表
	repaymentRecordListApi(params) {
		return post(`/business/v1/finance/repayment/repaymentRecordList`, params);
	}, //借款方-还款列表
	repaymentListApi(params) {
		return post(`/business/v1/finance/repayment/repaymentList`, params);
	}, //还款登记列表
	repaymentRegisterListApi(params) {
		return post(`/business/v1/finance/repayment/repaymentRegisterList`, params);
	}, //还款记录-导出全部
	repaymentRecordExportAllApi(params) {
		return post(`/business/v1/finance/repayment/repaymentRecord/exportAll`, params);
	}, //还款登记-导出全部
	repaymentRegisterExportAllApi(params) {
		return post(`/business/v1/finance/repayment/repaymentRegister/exportAll`, params);
	}, //还款列表-导出全部
	repaymentExportAllApi(params) {
		return post(`/business/v1/finance/repayment/repaymentList/exportAll`, params);
	}, //还款登记
	repaymentRegisterApi(params) {
		return post(`/business/v1/finance/repayment/repaymentRegister`, params);
	}, //还款页查询
	repaymentQueryApi(params) {
		return post(`/business/v1/finance/repayment/repaymentQuery`, params);
	}, //还款
	repaymentApi(params) {
		return post(`/business/v1/finance/repayment/repayment`, params);
	}, //确认还款
	confirmRepaymentApi(params) {
		return post(`/business/v1/finance/repayment/confirmRepayment`, params);
	}, //还款登记查询
	repaymentRegisterQueryApi(params) {
		return post(`/business/v1/finance/repayment/repaymentRegisterQuery`, params);
	}, //首页-还款日期
	getRepaymentDatesApi(params) {
		return post(`/business/v1/finance/repayment/getRepaymentDates`, params);
	}, //融资单详情信息查询
	getFinanceDetailApi(params) {
		return post(`/business/v1/finance/getDetail`, params);
	}, //核心企业-付款列表
	paymentListApi(params) {
		return post(`/business/v1/finance/payment/list`, params);
	}, //付款列表-导出全部
	paymentExportAllApi(params) {
		return post(`/business/v1/finance/payment/list/exportAll`, params);
	}, //付款查询-详情
	paymentQueryApi(params) {
		return post(`/business/v1/finance/payment/query`, params);
	}, //付款记录-导出全部
	paymentRecordExportAllApi(params) {
		return post(`/business/v1/finance/payment/record/exportAll`, params);
	}, //付款登记列表
	paymentRegisterListApi(params) {
		return post(`/business/v1/finance/payment/register/list`, params);
	}, //付款登记-导出全部
	paymentRegisterExportAllApi(params) {
		return post(`/business/v1/finance/payment/register/exportAll`, params);
	}, //付款登记查询-确认付款
	paymentRegisterQueryApi(params) {
		return post(`/business/v1/finance/payment/register/query`, params);
	}, //付款登记-确认付款-确认
	paymentRegisterApi(params) {
		return post(`/business/v1/finance/payment/register`, params);
	}, //付款-付款页面-确认付款
	paymentApi(params) {
		return post(`/business/v1/finance/payment/pay`, params);
	}, //付款-付款页面弹窗-确认付款
	confirmPaymentApi(params) {
		return post(`/business/v1/finance/payment/confirm/pay`, params);
	}, //付款记录列表
	paymentRecordListApi(params) {
		return post(`/business/v1/finance/payment/record/list`, params);
	}, //放款金额试算
	LoanTrialApi(financeId) {
		return get(`/business/v1/finance/` + financeId + `/loan/trial`);
	},


	//单据列表
	documentListApi(params) {
		return post(`/business/v1/bill/payableList`, params);
	}, //单据管理添加
	documentAddApi(params) {
		return post(`/business/v1/bill/add`, params);
	}, //融资-添加合同
	addContractApi(params) {
		return post(`/business/v1/bill/addContract`, params);
	},

	//批量导入ecxel(应收-核心企业)
	importBillApi(params) {
		return post(`/business/v1/bill/importBill`, params);
	}, //批量导入ecxel(应收-除核心企业)
	importBillAllApi(params) {
		return post(`/business/v1/bill/importBillAll`, params);
	}, //批量导入ecxel(应付-核心企业)
	importBillPayApi(params) {
		return post(`/business/v1/bill/importBillPay`, params);
	}, //批量导入ecxel(应付-除核心企业)
	importBillAllPayApi(params) {
		return post(`/business/v1/bill/importBillAllPay`, params);
	}, //单据编辑
	billModifyApi(params) {
		return post(`/business/v1/bill/updateBill`, params);
	}, //单据删除
	billDeleteApi(params) {
		return post(`/business/v1/bill/deleteBill`, params);
	}, //详情单据信息
	billDetailApi(params) {
		return post(`/business/v1/bill/detail`, params);
	}, //详情合同信息
	contractDetailApi(params) {
		return post(`/business/v1/bill/contractDetail`, params);
	}, //详情发票信息
	invoiceDetailApi(params) {
		return post(`/business/v1/bill/invoiceDetail`, params);
	}, //全部导出ecxel(应收-核心企业)
	exportDocketApi(params) {
		return post(`/business/v1/bill/export`, params);
	}, //全部导出ecxel(应收-除核心企业)
	exportDocketAllApi(params) {
		return post(`/business/v1/bill/exportAll`, params);
	}, //全部导出ecxel(应付-核心企业)
	exportPayApi(params) {
		return post(`/business/v1/bill/exportPay`, params);
	}, //全部导出ecxel(应付-除核心企业)
	exportPayAllApi(params) {
		return post(`/business/v1/bill/exportAllPay`, params);
	},


	//首页-金融产品总览(除了借款方)
	financeProductsApi(params) {
		return post(`/business/v1/finance/overview/financeProducts`, params);
	}, //首页-金融产品总览(借款方)
	financeProductBorrowerApi(params) {
		return post(`/business/product/financeProduct`, params);
	}, //首页-经办事项
	doneQueryApi(params) {
		return post(`/business/audit/doneQuery`, params);
	},

	//首页-融资单列表查询
	borrowerFinanceListApi(params) {
		return post(`/business/v1/finance/list`, params);
	},

	//查询还款计划
	repaymentPlanQueryApi(params) {
		return post(`/business/v1/finance/repaymentPlan/query`, params);
	},

	//还款计划试算
	repaymentPlanCreateApi(params) {
		return post(`/business/v1/finance/repaymentPlan/pre/create`, params);
	},

	//融资单单据信息查询
	financeBillListApi(params) {
		return post(`/business/v1/finance/bill/list`, params);
	}, //融资申请流程
	getFinanceProcessApi(params) {
		return post(`/business/v1/finance/getProcess`, params);
	}, //待放款列表
	pendingLoanListApi(params) {
		return post(`/business/v1/finance/loan/pending/page`, params);
	}, //待放款导出
	pendingExportListApi(params) {
		return post(`/business/v1/finance/loan/pending/export`, params);
	}, //已放款列表
	loanRecordListApi(params) {
		return post(`/business/v1/finance/loan/record/page`, params);
	}, //已放款列表导出
	loanRecordExportListApi(params) {
		return post(`/business/v1/finance/loan/record/export`, params);
	}, //放款
	loanApi(params) {
		let url = `/business/v1/finance/${params.financeId}/loan`
		delete params.financeId
		return put(url, params);
	}, //筑信：放款
	loanZxApi(params) {
		return post(`/business/v1/finance/apply/notice`, params);
	}, //融资单确认提交校验
	financeConfirmCheckApi(params) {
		return post(`/business/v1/finance/confirmCheck`, params);
	}, //项目列表
	projectQueryListApi(params) {
		return post(`/business/v1/project/queryList`, params);
	}, //项目添加
	projectAddApi(params) {
		return post(`/business/v1/project/add`, params);
	}, //项目修改
	projectModifyApi(params) {
		return post(`/business/v1/project/modify`, params);
	}, //项目修改-启用
	projectModifyInfoApi(params) {
		return post(`/business/v1/project/anableProject`, params);
	}, //项目禁用/启用
	projectModifyStateApi(params) {
		return post(`/business/v1/project/modifyState`, params);
	}, //准入列表
	creditQueryListApi(params) {
		return post(`/business/v1/credit/queryList`, params);
	}, //准入借款方详情
	creditDetailApi(params) {
		return post(`/business/v1/credit/detail`, params);
	}, //准入产品详情
	productDetailApi(params) {
		return post(`/business/v1/credit/productDetail`, params);
	}, //准入产品详情
	creditModifyApi(params) {
		return post(`/business/v1/credit/modify`, params);
	}, //查询所有项目
	projectEnableListApi(params) {
		return post(`/business/v1/project/enableList`, params);
	}, //查询项目下的核心企业或项目和核心企业下的所有资金方
	enterpriseCreditGetAllOrgApi(params) {
		return post(`/business/enterpriseCredit/getAllOrg`, params);
	},


	//资金通道查询
	getCapitalChannelApi(params) {
		return post(`/business/v1/finance/getCapitalChannel`, params);
	}, //融资单据附件-合同列表
	getContractListApi(params) {
		return post(`/business/v1/finance/bill/annex/getContractList`, params);
	}, //融资单据附件-发票列表
	getInvoiceListApi(params) {
		return post(`/business/v1/finance/bill/annex/getInvoiceList`, params);
	}, //作废
	projectNullifyApi(params) {
		return post(`/business/v1/project/projectNullify`, params);
	},

	// 查询企业业务角色
	getQueryOrgRoleApi(params) {
		return post(`/base/v1/org/queryOrgRole`, params);
	}, // 添加企业业务角色
	getAddOrgRoleApi(params) {
		return post(`/base/v1/org/addOrgRole`, params);
	},

	// 查询供应商列表
	getSupplierListApi(params) {
		return post(`/base/supplier/list`, params);
	}, // 添加供应商
	getSupplierAddApi(params) {
		return post(`/base/supplier/add`, params);
	}, // 修改供应商
	getSupplierUpdateApi(params) {
		return post(`/base/supplier/update`, params);
	}, // 删除供应商
	getSupplierDeleteApi(params) {
		return post(`/base/supplier/delete`, params);
	}, // 导出供应商全部
	getSupplierExportAllApi(params) {
		return post(`/base/supplier/exportAll`, params);
	}, // 批量导入供应商
	getSupplierImportBatchApi(params) {
		return post(`/base/supplier/exportAll`, params);
	},

	// 额度管理-查询分页列表
	getBusinessV1PlatFormQueryListApi(params) {
		return post(`/business/v1/platForm/queryList`, params);
	}, // 额度管理-平台额度新增
	getBusinessV1PlatFormAddApi(params) {
		return post(`/business/v1/platForm/add`, params);
	}, // 额度管理-修改
	getBusinessV1PlatFormModifyApi(params) {
		return post(`/business/v1/platForm/modify`, params);
	},

	// 出票信息列表
	getTicketDrawListApi(params) {
		return post(`/scb/v1/issue/register/list`, params);
	}, // 承兑应答列表
	getIssueAcceptListApi(params) {
		return post(`/scb/v1/issue/accept/reply/list`, params);
	}, // 收票应答列表
	getIssueReceiptListApi(params) {
		return post(`/scb/v1/issue/receipt/reply/list`, params);
	}, // 出票数据查询列表
	getDrawListApi(params) {
		return post(`/scb/v1/issue/list`, params);
	}, // 首页-待收票信息列表
	getReceivedListApi(params) {
		return post(`/scb/v1/issue/to/be/received/list`, params);
	}, // 出票信息新增
	addTicketDrawerApi(params) {
		return post(`/scb/v1/issue/add`, params);
	}, // 出票信息详情
	getTicketDrawerDetailApi(params) {
		return post(`/scb/v1/issue/getDetail`, params);
	}, // 出票信息编辑
	updateTicketDrawerApi(params) {
		return post(`/scb/v1/issue/edit`, params);
	}, // 合同编号生成
	generateContractNumApi(params) {
		return post(`/scb/v1/issue/contract/no/generate`, params);
	}, // 上传发票-发票批量解析
	analysisInvoiceApi(params) {
		return post(`/scb/v1/issue/invoice/batch/parse`, params);
	}, // 上传发票-发票批量新增
	addInvoiceApi(params) {
		return post(`/scb/v1/issue/invoice/batch/add`, params);
	}, // 获取发票池列表
	getInvociePoolListApi(params) {
		return post(`/scb/v1/issue/invoice/pool/list`, params);
	}, // 发票金额统计(已存入发票池中的发票统计)
	getInvoiceStatisticsApi(params) {
		return post(`/scb/v1/issue/invoice/pool/statistics`, params);
	}, // 金额统计（发票上传页面解析出的数据）
	getInvoiceAmountApi(params) {
		return post(`/scb/v1/issue/amount/statistics`, params);
	}, // 上传贸易背景/重新提交贸易背景
	uploadTradeBgDrawApi(params) {
		return post(`/scb/v1/issue/trade/background/upload`, params);
	}, // 票据合同信息
	queryContractApi(params) {
		return post(`/scb/v1/issue/contract/query`, params);
	}, // 票据发票信息列表
	getInvoiceDetailApi(params) {
		return post(`/scb/v1/issue/invoice/list`, params);
	}, // 核对信息
	checkDrawInformationApi(params) {
		return post(`/scb/v1/issue/info/check`, params);
	}, // 出票申请审核
	auditDrawApplyApi(params) {
		return post(`/scb/v1/issue/apply/audit`, params);
	}, // 贸易背景核对
	checkTradeBgInformationApi(params) {
		return post(`/scb/v1/issue/trade/background/check`, params);
	}, // 出票贸易背景审核
	auditTradeBgApi(params) {
		return post(`/business/v1/ticket/trade/background/audit`, params);
	}, // 票面凭证查询
	queryVoucherApi(params) {
		return post(`/scb/v1/issue/ticket/voucher/query`, params);
	}, // 提示承兑申请
	applyPreAcceptApi(params) {
		return post(`/scb/v1/issue/prompt/accept/apply`, params);
	}, // 提示承兑申请审核
	auditPreAcceptApplyApi(params) {
		return post(`/scb/v1/issue/prompt/accept/apply/audit`, params);
	}, // 承兑应答申请
	applyAcceptRespApi(params) {
		return post(`/scb/v1/issue/accept/response/apply`, params);
	}, // 承兑应答申请审核
	auditAcceptRespApplyApi(params) {
		return post(`/scb/v1/issue/accept/response/apply/audit`, params);
	}, // 提示收票申请
	applyPreReceiptApi(params) {
		return post(`/scb/v1/issue/prompt/receipt/apply`, params);
	}, // 提示收票申请审核
	auditPreReceiptApplyApi(params) {
		return post(`/scb/v1/issue/prompt/receipt/apply/audit`, params);
	}, // 收票应答申请
	applyReceiptRespApi(params) {
		return post(`/scb/v1/issue/receipt/response/apply`, params);
	}, // 收票应答申请审核
	auditReceiptRespApplyApi(params) {
		return post(`/scb/v1/issue/receipt/response/apply/audit`, params);
	},

	// 贴现列表查询接口
	getDiscountListApi(params) {
		return post(`/scb/v1/discount/list`, params);
	}, // 贴现利息试算-贴现
	trialInterestApi(params) {
		return post(`/scb/v1/discount/interest/trial`, params);
	}, // 贴现费用试算接口
	trialFeeApi(params) {
		return post(`/scb/v1/discount/fee/trial`, params);
	}, // 贴现申请-贴现
	discountApplyApi(params) {
		return post(`/scb/v1/discount/apply`, params);
	}, // 贴现申请-编辑
	discountEditApi(params) {
		return post(`/scb/v1/discount/edit`, params);
	}, // 贴现重新申请-贴现
	discountReapplyApi(params) {
		return post(`/scb/v1/discount/reapply`, params);
	}, // 贴现贸易背景上传接口
	uploadTradeBgDiscountApi(params) {
		return post(`/scb/v1/discount/trade/background/upload`, params);
	}, // 贴现详情-贴现
	getDiscountDetailApi(params) {
		return post(`/scb/v1/discount/details`, params);
	}, // 贴现费用详情接口
	getDiscountFeeDetailApi(params) {
		return post(`/scb/v1/discount/fee/details`, params);
	}, // 贴现费用支付状态查询接口
	queryPaymentStatusApi(params) {
		return post(`/scb/v1/discount/fee/payment/status/query`, params);
	}, // 贴现线下确认支付接口
	payDiscountOfflineApi(params) {
		return post(`/scb/v1/discount/offline/confirmation/payment`, params);
	}, // 贴现申请审核接口
	auditDiscountApplyApi(params) {
		return post(`/scb/v1/discount/apply/audit`, params);
	}, // 贴现申请核对接口
	checkDiscountApplyApi(params) {
		return post(`/scb/v1/discount/apply/verification`, params);
	},

	// 贴现渠道列表查询接口
	getDiscountChannelListApi(params) {
		return post(`/scb/v1/discount/channel/list`, params);
	}, // 贴现渠道导出所有接口
	getDiscountChannelExportApi(params) {
		return post(`/scb/v1/discount/channel/export/all`, params);
	}, // 贴现渠道费率设置接口
	getDiscountChannelRateApi(params) {
		return post(`/scb/v1/discount/channel/rate/set`, params);
	}, // 贴现费用列表查询接口
	getDiscountFeeListApi(params) {
		return post(`/scb/v1/discount/fee/list`, params);
	}, // 信批状态查询接口
	getDisclosureQueryApi(params) {
		return post(`/scb/v1/disclosure/status/query`, params);
	}, // 信批详情接口
	getDisclosureDetailsApi(params) {
		return post(`/scb/v1/disclosure/details`, params);
	}, // 信批申请接口
	getDisclosureApplyApi(params) {
		return post(`/scb/v1/disclosure/apply`, params);
	}, // 贴现费用收款确认接口
	getDiscountFeeConfirmApi(params) {
		return post(`/scb/v1/discount/fee/receipt/confirm`, params);
	}, // 贴现费用收款审核接口
	getDiscountFeeAuditApi(params) {
		return post(`/scb/v1/discount/fee/collection/audit`, params);
	}, // 贴现费用详情接口
	getDiscountFeeDetailsApi(params) {
		return post(`/scb/v1/discount/fee/details`, params);
	}, // 信批审核接口
	getDisclosureAuditApi(params) {
		return post(`/scb/v1/disclosure/audit`, params);
	}, //查询工作流是否有配置
	haveConfigApi(params) {
		return post(`/workflow/template/config/have`, params);
	}, //查询贴现机构列表
	getInstitutionsListApi(params) {
		return post(`/scb/v1/institutions/list`, params);
	},

	//支付-获取订单列表
	getOrderListApi(params) {
		return post(`/payCenter/v1/cashier/orderList`, params);
	}, //支付-获取订单中渠道列表
	getChannelListApi(params) {
		return get(`/payCenter/v1/cashier/channelList`, params);
	},

	//支付-服务费配置分页列表
	getServiceListApi(params) {
		return post(`/payCenter/v1/cashier/serviceCharge/page`, params);
	}, //支付-服务费配置详情
	getServiceDetailApi(params) {
		return post(`/payCenter/v1/cashier/serviceCharge/${params.serviceChargeId}`);
	}, //支付-服务费配置添加
	getServiceAddApi(params) {
		return post(`/payCenter/v1/cashier/serviceCharge`, params);
	}, //支付-服务费配置修改
	getServiceEditApi(params) {
		return put(`/payCenter/v1/cashier/serviceCharge/${params.serviceChargeId}`, params);
	}, //支付-服务费配置状态修改
	getServiceChangeApi(params) {
		return put(`/payCenter/v1/cashier/serviceCharge/${params.serviceChargeId}/status`);
	},

	//支付-订单配置分页列表
	getorderConfigPageApi(params) {
		return post(`/payCenter/v1/cashier/orderConfig/page`, params);
	}, //支付-订单配置详情
	getorderConfigDetailApi(params) {
		return get(`/payCenter/v1/cashier/orderConfig/${params.orderConfigId}`, params);
	}, //支付-订单配置添加
	getorderConfigAddApi(params) {
		return post(`/payCenter/v1/cashier/orderConfig`, params);
	}, //支付-订单配置修改
	getorderConfigEditApi(params) {
		return put(`/payCenter/v1/cashier/orderConfig/${params.orderConfigId}`, params);
	}, //支付-订单配置状态修改
	getorderConfigChangeApi(params) {
		return put(`/payCenter/v1/cashier/orderConfig/${params.orderConfigId}/status`, params);
	},

	//支付-获取渠道列表
	getChannelPageApi(params) {
		return post(`/payCenter/v1/cashier/channel/page`, params);
	}, //支付-渠道详情
	getChannelDetailApi(params) {
		return get(`/payCenter/v1/cashier/channel/${params.channelId}`);
	}, //支付-添加渠道
	getChannelAddApi(params) {
		return post(`/payCenter/v1/cashier/channel`, params);
	}, //支付-修改渠道
	getChannelEditApi(params) {
		return put(`/payCenter/v1/cashier/channel/${params.channelId}`, params);
	}, //支付-修改渠道状态
	getChannelStatusApi(params) {
		return put(`/payCenter/v1/cashier/channel/${params.channelId}/status`);
	},

	//支付-渠道步骤配置分页列表
	getOpenFlowPageApi(params) {
		return post(`/payCenter/v1/cashier/channel/${params.channelId}/openFlow/page`, params);
	}, //支付-渠道步骤配置详情
	getOpenFlowDetailApi(params) {
		return get(`/payCenter/v1/cashier/channel/${params.channelId}/openFlow/${params.openFlowId}`);
	}, //支付-渠道步骤配置添加
	getOpenFlowAddApi(params) {
		return post(`/payCenter/v1/cashier/channel/${params.channelId}/openFlow`, params);
	}, //支付-渠道步骤配置修改
	getOpenFlowEditApi(params) {
		return put(`/payCenter/v1/cashier/channel/1/openFlow/${params.openFlowId}`, params);
	}, //支付-渠道步骤配置状态修改
	getOpenFlowChangeApi(params) {
		return put(`/payCenter/v1/cashier/channel/1/openFlow/${params.openFlowId}/status`);
	}, //支付-支付流水
	getPayFlowApi(params) {
		return post(`/payCenter/v1/cashier/payFlow`, params);
	}, //支付-凭证下载
	getPayVoucherApi(params) {
		return get(`/payCenter/v1/cashier/payFlow/${params.payFlowId}/voucher`);
	}, //支付-交易流水导出
	getPayExportAllApi(params) {
		return post(`/payCenter/v1/cashier/payFlow/exportAll`, params);
	}, //支付-商户列表
	getMerchantListApi(params) {
		return post(`/payCenter/v1/cashier/merchantList`, params);
	}, //支付-商户详情
	getMerchantDetailApi(params) {
		return get(`/payCenter/v1/cashier/merchantList/${params.id}`);
	}, //支付-商户详情
	getDownloadApi(params) {
		return post(`/payCenter/v1/resourceFile/download`, params);
	}, queryAreaCodeApi(params) {
		return get(`/base/area/standardAreaCode/${params.standardAreaCode}`, params);
	},

	// 提示承兑撤销申请
	applyPreAcceptUndoApi(params) {
		return post(`/scb/v1/issue/prompt/accept/revoke/apply`, params);
	}, // 提示承兑撤销申请审核
	auditPreAcceptUndoApplyApi(params) {
		return post(`/scb/v1/issue/prompt/accept/revoke/apply/audit`, params);
	}, // 提示收票撤销申请
	applyPreReceiptUndoApi(params) {
		return post(`/scb/v1/issue/prompt/receipt/revoke/apply`, params);
	}, // 提示收票撤销申请审核
	auditPreReceiptUndoApplyApi(params) {
		return post(`/scb/v1/issue/prompt/receipt/revoke/apply/audit`, params);
	}, // 提示付款列表
	getPaymentListApi(params) {
		return post(`/scb/v1/accept/prompt/payment/list`, params);
	}, // 承兑信息详情
	getPaymentDetailApi(params) {
		return post(`/scb/v1/accept/info/getDetail`, params);
	}, // 提示付款申请
	applyPrePayApi(params) {
		return post(`/scb/v1/accept/prompt/payment/apply`, params);
	}, // 提示付款申请审核
	auditPrePayApplyApi(params) {
		return post(`/scb/v1/accept/prompt/payment/apply/audit`, params);
	}, // 提示付款撤销申请
	applyPrePayUndoApi(params) {
		return post(`/scb/v1/accept/prompt/payment/revoke/apply`, params);
	}, // 提示付款申请撤销审核
	auditPrePayApplyUndoApi(params) {
		return post(`/scb/v1/accept/prompt/payment/revoke/apply/audit`, params);
	}, // 付款应答列表
	getPaymentRespListApi(params) {
		return post(`/scb/v1/accept/payment/reply/list`, params);
	}, // 承兑票据信息
	getAcceptTicketDetailApi(params) {
		return post(`/scb/v1/accept/ticket/info/query`, params);
	}, // 付款应答申请
	applyPayRespApi(params) {
		return post(`/scb/v1/accept/payment/reply/apply`, params);
	}, // 付款应答申请审核
	auditPayRespApplyApi(params) {
		return post(`/scb/v1/accept/payment/reply/apply/audit`, params);
	}, // 兑付信息查询列表
	getCashListApi(params) {
		return post(`/scb/v1/accept/list`, params);
	}, // 首页-待付款列表
	getToPayListApi(params) {
		return post(`/scb/v1/accept/to/be/paid/list`, params);
	}, // 首页-待背书签收列表
	getToReplayListApi(params) {
		return post(`/scb/v1/endorsement/to/be/reply/list`, params);
	}, // 首页-待出票保证应答列表
	queryDrawEnsureToReplyListApi(params) {
		return post('/scb/v1/issue/ensure/to/be/reply/list', params, true, 50000, "ap2");
	}, // 首页-待承兑保证应答列表
	queryAcceptEnsureToReplyListApi(params) {
		return post('/scb/v1/accept/ensure/to/be/reply/list', params, true, 50000, "ap2");
	}, // 首页-待背书保证应答列表
	queryEndorseEnsureToReplyListApi(params) {
		return post('/scb/v1/endorse/ensure/to/be/reply/list', params, true, 50000, "ap2");
	},
	// 产品承兑-产品列表
	queryproductsListApi(params) {
		return get('/scb/v1/products', params, true, 50000, "ap2");
	},
	// 查询下级字典
	queryDictionaryCascaderApi(params) {
		return post(`/business/dictionary/cascader?parentType=${params.parentType}&parentKey=${params.parentKey}`, {},true, 50000, "ap2");
	},
	// 系统管理-字典信息分页列表
	dictionaryPageListApi(params) {
		return post('/business/dictionary/page/list', params, true, 50000, "ap2");
	}, // 系统管理-字典列表查询
	dictionaryListApi(params) {
		return post('/business/dictionary/list', params, true, 50000, "ap2");
	}, // 系统管理-字典新增
	dictionaryAddApi(params) {
		return post('/business/dictionary/add', params, true, 50000, "ap2");
	}, // 系统管理-字典编辑
	dictionaryEditApi(params) {
		return post('/business/dictionary/edit', params, true, 50000, "ap2");
	}, // 系统管理-字典启用禁用
	dictionaryStatusUpdateApi(params) {
		return post('/business/dictionary/status/update', params, true, 50000, "ap2");
	},
	// 短链接列表
	getShortLinkListApi(params) {
		return post('/business/v1/short/link/list', params, true, 50000, "ap2");
	},
	// 短链接管理-短链接启用禁用
	updateShortLinkStatusApi(params) {
		return post('/business/v1/short/link/update', params, true, 50000, "ap2");
	},
	// 短链接管理-短链接信息新增
	addShortLinkApi(params) {
		return post('/business/v1/short/link/add', params, true, 50000, "ap2");
	},
	// 短链接管理-短链接信息修改
	editShortLinkApi(params) {
		return post('/business/v1/short/link/edit', params, true, 50000, "ap2");
	},
	// 短链接管理-短链接信息详情
	getShortLinkDetailApi(params) {
		return post('/business/v1/short/link/detail', params, true, 50000, "ap2");
	},

	// 资产池列表
	assetPoolListApi(params) {
		return post('/business/v1/abs/asset/pool/list', params, true, 50000, "ap2");
	},
	// 资产池启动
	assetPoolEnableApi(params) {
		return post('/business/v1/abs/asset/pool/enable', params, true, 50000, "ap2");
	},
	// 资产池暂停
	assetPoolDisableApi(params) {
		return post('/business/v1/abs/asset/pool/disable', params, true, 50000, "ap2");
	},
	// 资产池新增-导入数据
	assetPoolImportDataApi(params) {
		return post('/business/v1/abs/asset/pool/importData', params, true, 50000, "ap2");
	},
	// 资产池新增-确认
	assetPoolAddApi(params) {
		return post('/business/v1/abs/asset/pool/add', params, true, 50000, "ap2");
	},
	// 金额债务人数统计
	absStatisticsApi(params) {
		return post('/business/v1/abs/statistics', params, true, 50000, "ap2");
	},
	// 资产池-数据详情
	assetPoolDetailApi(params) {
		return post('/business/v1/abs/asset/pool/detail', params, true, 50000, "ap2");
	},
	// 资产池-数据详情合计
	assetPoolDetailTotalApi(params) {
		return post('/business/v1/abs/asset/pool/detail/total', params, true, 50000, "ap2");
	},
	// 资产池-数据详情分页
	assetPoolDetailPageApi(params) {
		return post('/business/v1/abs/asset/pool/detail/page', params, true, 50000, "ap2");
	},
	// 资产池维护-导入数据入库
	assetPoolImportAddApi(params) {
		return post('/business/v1/abs/asset/pool/importAdd', params, true, 50000, "ap2");
	},
	// 资产池维护-数据更新
	assetPoolUpdateApi(params) {
		return post('/business/v1/abs/asset/pool/update', params, true, 50000, "ap2");
	},
	// 资产池维护-资产删除
	assetPoolDeleteApi(params) {
		return post('/business/v1/abs/asset/pool/delete', params, true, 50000, "ap2");
	},

	// 开立签收列表
	letterOpenListApi(params) {
		return post('/business/v1/letter/open/sign/list', params, true, 50000, "ap2");
	},
	// 开立签收确认
	letterOpenConfirmApi(params) {
		return post('/business/v1/letter/open/sign/confirm', params, true, 50000, "ap2");
	},
	// 信单转让列表
	letterTransferListApi(params) {
		return post('/business/v1/letter/transfer/list', params, true, 50000, "ap2");
	},
	// 信单转让撤销
	letterTransferRevokeApi(params) {
		return post('/business/v1/letter/transfer/revoke', params, true, 50000, "ap2");
	},
	// 信单转让详情
	letterTransferGetDetailApi(params) {
		return post('/business/v1/letter/transfer/getDetail', params, true, 50000, "ap2");
	},
	// 信单转让发起列表
	letterTransferInitiateListApi(params) {
		return post('/business/v1/letter/transfer/initiate/list', params, true, 50000, "ap2");
	},
	// 信单转让申请/重新申请
	letterTransferApplyApi(params) {
		return post('/business/v1/letter/transfer/apply', params, true, 50000, "ap2");
	},
	// 信单转让签收列表
	letterTransferSignForListApi(params) {
		return post('/business/v1/letter/transfer/signFor/list', params, true, 50000, "ap2");
	},
	// 信单转让签收审核
	letterTransferSignForAuditApi(params,letterTransferId) {
		return post(`/business/v1/letter/transfer/signFor/${letterTransferId}/audit`, params, true, 50000, "ap2");
	},
	// 凭证签约配置分页列表
	testifyManageListApi(params) {
		return post('/business/v1/contract/common/sign/config/list', params );
	},
	// 凭证签约配置新增
	testifyManageAddApi(params) {
		return post('/business/v1/contract/common/sign/config/add', params );
	},
	// 凭证签约配置详情
	testifyManagefindDetailApi(params) {
		return post('/business/v1/contract/common/sign/config/getDetail', params );
	},
	// 凭证签约配置合同模板
	testifyManagecontractTemplateListApi(params) {
		return post('/business/v1/contract/common/sign/config/contract/template/list', params );
	},
	// 凭证签约配置修改
	testifyManageUpdateApi(params) {
		return post('/business/v1/contract/common/sign/config/update', params );
	},
	// 凭证签约配置删除
	testifyManageDeleteApi(params) {
		return post('/business/v1/contract/common/sign/config/delete', params );
	},
	// 合同模板文件查询
	queryContractTemplateFileApi(params) {
		return post(`/business/v1/contract/template/file/queryContractTemplateFile`, params);
	},
}
export default scfBusinessApi
