import {post} from '../http';
import { getStorage } from '../../utils/tools'

const loginApi = {
    //注释
    userLoginApi(params) {
        return post('/authentic/login', params,true,50000,"ap2");
    },
    getAesApi(params) {
        return post('/authentic/getAes', params,true,50000,"ap2");
    },
    // 发送短信
	getSendsmsApi(params) {
		return post(`/servicecenter/aliyun/sendsms`, params,true,50000,"ap2");
        // return post(`http://39.98.94.246:8094/app/mock/data/542`, params, true, 5000, 'ap5');
	},
    // 查询用户角色
	getIsRegisterApi(params) {
		return post(`/authentic/tel/isRegister`, params,true,50000,"ap2");
        // return post(`http://39.98.94.246:8094/app/mock/data/1878`, params, true, 5000, 'ap5');
	},
}
export default loginApi;

export async function registerApi(params){
    return post('/base/v1/user/reg', params,true,50000,"ap2");
} 
export async function sendsmsApi(params){
    return post('/servicecenter/aliyun/sendsms', params,true,50000,"ap2");
} 

export async function setDealPasswordApi(params){
    const userId = getStorage('userId')
    return post(`/base/v1/user/${userId}/trade/pwd`, params,true,50000,"ap2");
} 

export async function smsCodeLoginApi(params){
    return post(`/authentic/sms/login`, params,true,50000,"ap2");
} 

export async function forgotPasswordApi(params){
    return post(`/authentic/v1/user/pwd/forget`, params,true,50000,"ap2");
} 

export async function queryOpenStatusApi(params){
    return post(`/business/ticketApply/getTicketApply`, params,true,50000,"ap2");
} 