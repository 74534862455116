import React from 'react';
import { Spin } from 'antd';
import Loadable from 'react-loadable';

export default (loader) => {
    return Loadable({
        loader,
        loading() {
            return <div style={{textAlign:'center',margin:'60px auto'}}>
                 <Spin size="large" />
            </div>
        },
    });
}