import scfBusinessApi from '@/request/api/scfBusinessApi';
import { message } from 'antd';

const findDicLtApi = scfBusinessApi['findDicLtApi'];
const contractTemplatefindPageListApi = scfBusinessApi['contractTemplatefindPageListApi'];
const getAllProjectApi = scfBusinessApi['getAllProjectApi'];
const getProjectProductsApi = scfBusinessApi['getProjectProductsApi'];
const contractTemplateUploadApi = scfBusinessApi['contractTemplateUploadApi'];
const contractTemplatefindDetailApi = scfBusinessApi['contractTemplatefindDetailApi'];
const queryproductsListApi = scfBusinessApi['queryproductsListApi'];

export default {
    //命名空间
    namespace: "contractTemplateManage",
    //仓库初始化状态
    state: {
        contractTemplateManageListData: [],
        allProjectListData: [],
        allProductListData: [],
        addContractData: {},
        contractDetailData: {},
        capitalEnumerate: [],
        signatoryEnumerate: [],
        SMSEnumerate: [],
        contractTypesEnumerate: [],
        isProductEnumerate: [],
        browseContractData: '',
        SysField: [],
        RuleType: [],
        systemTypeCdList:[],
        productsList:[]
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        //页面接口
        //合同模板接口分页
        *getContractTemplateManageListData(action, { call, put }) {
            const result = yield call(contractTemplatefindPageListApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'contractTemplateManageListData', val: result.data } })
        },
        *getAllProjectListData(action, { call, put }) {
            const result = yield call(getAllProjectApi)
            yield put({ type: 'updateReducers', payload: { key: 'allProjectListData', val: result.data } })
        },
        *getAllProductListData(action, { call, put }) {
            const params = { companyCreditId: action.payload };
            const result = yield call(getProjectProductsApi, params)
            yield put({ type: 'updateReducers', payload: { key: 'allProductListData', val: result.data } })
        },
        *getAddContractData(action, { call, put }) {
            let result = null;
            try {
                result = yield call(contractTemplateUploadApi, action.payload)
                yield put({ type: 'updateReducers', payload: { key: 'addContractData', val: result.data } })
            }
            catch(err) {
                message.error(err.message)
                return err;
            }
        },
        *getContractDetailData(action, { call, put }) {
            const params = { contractTemplateId: action.payload };
            const result = yield call(contractTemplatefindDetailApi, params)
            yield put({ type: 'updateReducers', payload: { key: 'contractDetailData', val: result.data } })
        },
        *getClearContractDetailData(action, { call, put }) {
            const params = { contractTemplateId: action.payload };
            const result = yield call(contractTemplatefindDetailApi, params)
            result.data.configs = []
            yield put({ type: 'updateReducers', payload: { key: 'contractDetailData', val: result.data } })
        },
        //静态枚举
        *getCapitalEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'capitalEnumerate', val: result.data } })
        },
        *getSignatoryEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'signatoryEnumerate', val: result.data } })
        },
        *getSMSEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'SMSEnumerate', val: result.data } })
        },
        *getContractTypesEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'contractTypesEnumerate', val: result.data } })
        },
        *getisProductEnumerate(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'isProductEnumerate', val: result.data } })
        },
        *getSysField(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'SysField', val: result.data } })
        },
        *getRuleType(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'RuleType', val: result.data } })
        },
        *getSystemTypeCd(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'systemTypeCdList', val: result.data } })
        },
        *geQueryproductsList(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(queryproductsListApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'productsList', val: result.data } })
        }
    }
};
