import { post , get , put} from '../http';
import { getStorage } from '../../utils/tools'

export async function queryOrgsApi(params){
    return post('/open_api/v1/orgs', params,true,50000,"ap2");
} 

export async function queryGenApi(params){
    return post('/open_api/v1/rsa/gen', params,true,50000,"ap2");
} 

export async function queryGroupApi(params){
    return post('/open_api/v1/apis/group', params,true,50000,"ap2");
} 

export async function queryOpenApi(params){
    return post('/open_api/v1/open', params,true,50000,"ap2");
} 
//用户组列表
export async function queryGroupsPageApi(params , orgId){
    return post(`/open_api/v1/orgs/${orgId}/groups/page`, params,true,50000,"ap2");
} 
//添加用户组
export async function queryAddGroupsApi(params ,orgId){
    return post(`/open_api/v1/orgs/${orgId}/groups`, params,true,50000,"ap2");
} 
//删除用户组
export async function queryDeleteGroupsApi(params , orgId , groupId ){
    return post(`/open_api/v1/orgs/${orgId}/groups/${groupId}/del`, params,true,50000,"ap2");
} 
//获取用户列表
export async function querygetUserDataPageApi(params , orgId ){
    return post(`/open_api/v1/orgs/${orgId}/users/page`, params,true,50000,"ap2");
} 
//创建用户
export async function queryCreatUserApi(params , orgId ){
    return post(`/open_api/v1/orgs/${orgId}/users`, params,true,50000,"ap2");
} 
//给用户组添加用户
export async function querygetAddUserGroupApi(params , orgId , groupId ){
    return post(`/open_api/v1/orgs/${orgId}/groups/${groupId}/users`, params,true,50000,"ap2");
} 
//删除用户
export async function queryDeleteUserApi(params , orgId , userId ){
    return post(`/open_api/v1/orgs/${orgId}/users/${userId}/del`, params,true,50000,"ap2");
} 
//给用户添加用户组
export async function querygetAddUserApi(params , orgId , userId ){
    return post(`/open_api/v1/orgs/${orgId}/users/${userId}/groups`, params,true,50000,"ap2");
} 
//用户详情
export async function queryUserDetailApi(params , orgId , userId ){
    return get(`/open_api/v1/orgs/${orgId}/users/${userId}`, params,true,50000,"ap2");
} 
//生成用户AccessKey
export async function queryAccessKeyApi(params , orgId , userId ){
    return post(`/open_api/v1/orgs/${orgId}/users/${userId}/access`, params,true,50000,"ap2");
} 
//用户组详情
export async function queryUserGroupDetailApi(params , orgId , groupId ){
    return get(`/open_api/v1/orgs/${orgId}/groups/${groupId}`, params,true,50000,"ap2");
} 
//移除授权
export async function querydeleteAuthorizationApi(params , orgId , policyId ){
    return post(`/open_api/v1/orgs/${orgId}/policies/${policyId}/del`, params,true,50000,"ap2");
} 
//权限列表
export async function querypoliciesApi(params,orgId){
    return post(`/open_api/v1/orgs/${orgId}/policies/page`, params,true,50000,"ap2");
} 
//添加权限内的策略列表
export async function queryRMpoliciesApi(params,orgId){
    return post(`/open_api/v1/policies/page`, params,true,50000,"ap2");
} 
//添加权限
export async function queryAddRMpoliciesApi(params,orgId){
    return post(`/open_api/v1/orgs/${orgId}/policies`, params,true,50000,"ap2");
} 
//搜索用户和用户组
export async function querySearchdataSelectApi(params,orgId){
    return post(`/open_api/v1/orgs/${orgId}/search/`, params,true,50000,"ap2");
} 
//查询流水
export async function querystatementsApi(params){
    return post(`/open_api/v1/statements`, params,true,50000,"ap2");
} 
//查询流水详情
export async function queryencryptApi(params,statementId){
    return post(`/open_api/v1/statements/${statementId}`, params,true,50000,"ap2");
} 
//查询流水加密详情
export async function queryencrypkeytApi(params,statementId){
    return post(`/open_api/v1/statements/${statementId}/encrypt`, params,true,50000,"ap2");
} 
//将用户从用户组移除
export async function queryremoteUserGroupsApi(params,orgId,groupId,userId){
    return post(`/open_api/v1/orgs/${orgId}/groups/${groupId}/users/${userId}/del`, params,true,50000,"ap2");
} 
//获取企业列表
export async function queryorgsApi(params){
    return post(`/open_api/v1/orgs/page`, params,true,50000,"ap2");
} 
//获取企业密钥
export async function queryOrgsecret(params,orgId){
    console.log(params)
    return put(`/open_api/v1/orgs/${orgId}/secret`, params,true,50000,"ap2");
} 
//获取企业详情
export async function queryOrgdetail(params,orgId){
    return get(`/open_api/v1/orgs/${orgId}`, params,true,50000,"ap2");
} 
//获取已开通得Api
export async function queryOpenOrgapisApi(params,orgId){
    return get(`/open_api/v1/orgs/${orgId}/apis`, params,true,50000,"ap2");
} 
//修改机构开通Api
export async function querymodifyOrgapisApi(params,orgId){
    return put(`/open_api/v1/orgs/${orgId}/apis`, params,true,50000,"ap2");
} 
//修改企业状态：状态：0启用,1禁用
export async function queryModifyStatusApi(params,orgId){
    return put(`/open_api/v1/orgs/${orgId}/status`, params,true,50000,"ap2");
} 
