const CryptoJS = require('crypto-js');

//函数防抖
export function debounce(event, wait) {
    let timer = null;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            event(...args);
        }, wait);
    };
};
  
//设置localStorage
export const setStorage = (name, content) => {
    if(!name) return;
    if(typeof content !== "string") {
        content = JSON.stringify(content);
    }
    window.localStorage.setItem(name, content);
};

//获取localStorage
export const getStorage = name => {
    if(!name) return;
    return window.localStorage.getItem(name);
};

//删除localStorage
export const removeStorage = name => {
    if(!name) return;
    window.localStorage.removeItem(name);
};

//设置cookie
export const setCookie = (name, value, day)=>{
    var date = new Date();
	date.setDate(date.getDate() + day);
	document.cookie = name + '=' + value + ';expires=' + date;
}

//获取cookie
export const getCookie=(name)=>{
    var reg = RegExp(name + '=([^;]+)');
	var arr = document.cookie.match(reg);
	if (arr) {
		return arr[1];
	} else {
		return '';
	}
}
//删除cookie
export const delCookie=(name)=>{
    setCookie(name, null, -1);
}

//获取url 后面的参数
export const getUrlParam = (name) => {
    var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r!=null) return unescape(r[2]); return null;
};

// 设置年月日时分秒
export const getDate=()=>{
    let myDate = new Date(); //实例一个时间对象；
    let Year = myDate.getFullYear();   //获取系统的年；
    let Month = myDate.getMonth()+1;   //获取系统月份，由于月份是从0开始计算，所以要加1
    let Date = myDate.getDate(); // 获取系统日，
    let Hours = myDate.getHours(); //获取系统时，
    let Minutes = myDate.getMinutes(); //分
    let Seconds = myDate.getSeconds(); //秒
    return Year + '-' + Month + '-' + Date + '-' + Hours + '-' + Minutes + '-' + Seconds
}



//兼容浏览器的关闭当前窗口
export const closeCurrentWindow=()=>{
    var browserName = navigator.appName;
    if(browserName == 'Netscape') {
        var opened = window.open('about:blank','_self');
        opened.opener = null;
        opened.close();
    } else {
        window.opener = null;
        window.open('','_self');
        window.close();
    }
}

//兼容浏览器的关闭父窗口
export const closeOpenerWindow=()=>{
    var browserName = navigator.appName;
    if(browserName == 'Netscape') {
        var opened = window.opener.open('about:blank','_self');
        opened.opener = null;
        opened.close();
    } else {
        window.opener.opener=null;
        window.opener.open('', '_self',);
        window.opener.close();
    }
}

//兼容浏览器的更新父窗口
export const refreshOpenerWindow=()=>{
    if(window.opener!=null && !window.opener.closed&&window.opener.location.href!=window.location.href){
        window.opener.location.href=window.opener.location.href;
    }
}

//将日期格式化为-连接
export const getNowFormatDate=(theDate)=>{
    var day = theDate;
    var Year = 0;
    var Month = 0;
    var Day = 0;
    var CurrentDate = "";
    // 初始化时间
    Year= day.getFullYear();
    Month= day.getMonth()+1;
    Day = day.getDate();
    CurrentDate += Year + "-";
    if (Month >= 10 ){
        CurrentDate += Month + "-";
    }
    else{
        CurrentDate += "0" + Month + "-";
    }
    if (Day >= 10 ){
        CurrentDate += Day ;
    }
    else{
        CurrentDate += "0" + Day ;
    }
    return CurrentDate;
}

//加密
const key = CryptoJS.enc.Utf8.parse("a3edf1744a3bd21fe504b8c21b57d52b");  //密钥
const iv = CryptoJS.enc.Utf8.parse('ab142ghjky89wqcv');   //密钥偏移量

export const Encrypt = (word) => {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.ciphertext.toString();
}

export const dealStr = (str) => {
    let newStr = ''
    if(str.length > 6){
        newStr = str.slice(0,6) + '...'
        return newStr
    } else {
        return str
    }
}