import scfBusinessApi from '@/request/api/scfBusinessApi';

const findDicLtApi = scfBusinessApi['findDicLtApi'];
const findPageListApi = scfBusinessApi['findPageListApi'];
const getAllProjectSignConfigApi = scfBusinessApi['getAllProjectSignConfigApi'];
const signConfigFindDetailApi = scfBusinessApi['signConfigFindDetailApi'];
const getProjectProductsApi = scfBusinessApi['getProjectProductsApi'];
const findContractTemplatesApi = scfBusinessApi['findContractTemplatesApi']
const queryproductsListApi = scfBusinessApi['queryproductsListApi'];


export default {
    //命名空间
    namespace: "signingConfiguration",
    //仓库初始化状态
    state: {
        signingConfigurationList: [],
        projectList: [],
        productList: [],
        detailData: {},
        enableData: [],
        systemTypeCdList:[],
        productsList:[]
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        //页面接口
        *getSigningConfigurationList(action, { call, put }) {
            const result = yield call(findPageListApi, action.payload);
            yield put({ type: 'updateReducers', payload: { key: 'signingConfigurationList', val: result.data } })
        },
        *getProjectList(action, { call, put }) {
            const result = yield call(getAllProjectSignConfigApi);
            yield put({ type: 'updateReducers', payload: { key: 'projectList', val: result.data } })
        },
        *getProductList(action, { call, put }) {
            const result = yield call(getProjectProductsApi, action.payload);
            yield put({ type: 'updateReducers', payload: { key: 'productList', val: result.data } })
        },
        *getDetailData(action, { call, put }) {
            const result = yield call(signConfigFindDetailApi, action.payload);
            yield put({ type: 'updateReducers', payload: { key: 'detailData', val: result.data } })
        },
        *getEnableData(action, { call, put }) {
            const result = yield call(findContractTemplatesApi, action.payload);
            yield put({ type: 'updateReducers', payload: { key: 'enableData', val: result.data } })
        },
        *getSystemTypeCd(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(findDicLtApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'systemTypeCdList', val: result.data } })
        },
        *geQueryproductsList(action, { call, put }) {
            const params = { dicType: action.payload };
            const result = yield call(queryproductsListApi, params);
            yield put({ type: 'updateReducers', payload: { key: 'productsList', val: result.data } })
        }
    },
};
