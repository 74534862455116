
import { queryOrgsApi } from '../../request/api/permissions'
import { getEndorsementscbGetDetailApi } from  '../../request/api/endrose'

export default {
    //命名空间
    namespace: "endorse",
    //仓库初始化状态
    state: {
        permissionsOrgs:[],
        permissionsIndex:0,
        permissionsOrg:0,
        endorseData: {},
        pledgeData:{}
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        *getPermissionsOrgs(action,{ call , put }){
            const result = yield call(queryOrgsApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'permissionsOrgs', val: result.data } })
        },
        *getPermissionsIndex(action,{ put }){
            const result = action.payload
            yield put({ type: 'updateReducers', payload: { key: 'permissionsIndex', val: result.index } })
        },
        *getPermissionsOrg(action,{ put }){
            const result = action.payload
            yield put({ type: 'updateReducers', payload: { key: 'permissionsOrg', val: result.value } })
        },
        *getEndorse(action,{ put }){
            const result = action.payload
            yield put({ type: 'updateReducers', payload: { key: 'endorseData', val: result } })
        },
        *getEndorseAsyn(action,{call, put }){
            const result = yield call(getEndorsementscbGetDetailApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'endorseData', val: result } })
        },
        *getPledge(action,{ put }){
            const result = action.payload
            yield put({ type: 'updateReducers', payload: { key: 'pledgeData', val: result } })
        },
    }
};
