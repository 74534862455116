
import { queryOrgsApi } from '../../request/api/permissions'

export default {
    //命名空间
    namespace: "permissions",
    //仓库初始化状态
    state: {
        permissionsOrgs:[],
        permissionsIndex:0,
        permissionsOrg:0
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        *getPermissionsOrgs(action,{ call , put }){
            const result = yield call(queryOrgsApi, action.payload)
            yield put({ type: 'updateReducers', payload: { key: 'permissionsOrgs', val: result.data } })
        },
        *getPermissionsIndex(action,{ put }){
            const result = action.payload
            yield put({ type: 'updateReducers', payload: { key: 'permissionsIndex', val: result.index } })
        },
        *getPermissionsOrg(action,{ put }){
            const result = action.payload
            yield put({ type: 'updateReducers', payload: { key: 'permissionsOrg', val: result.value } })
        }
    }
};
