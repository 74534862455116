//项目入口文件
import dva from "dva";
import { persistEnhancer } from 'dva-model-persist';
import router from "@/router";
import history from "@/utils/history";
import { createModel } from "@/store";
import createLoading from 'dva-loading';
import { message } from 'antd';
import "@/assets/css/mixin.scss";
import "@/assets/css/style.scss";
import "@/assets/css/common.scss";
import "@/assets/css/iconfont.scss";
const NODE_ENV = process.env.NODE_ENV;
if (NODE_ENV === "development") {
    // window.API_HOST = "http://192.168.0.177:12001/service";
    // window.API_HOST = "http://scf-dev.qihuian.com/service";
    // window.IMAGE_HOST = "http://scf-dev.qihuian.com";
    window.IMAGE_HOST = "http://scf-test.qihuian.com";
    window.API_HOST = "http://scf-test.qihuian.com/service";
    // window.JDH_HOST = "https://qihuian-dev.jdh.com.cn";
    window.PAY_HOST = 'http://pay-test.qihuian.com/admin/payCenter/error?result=' //测试环境支付系统
} else {
    window.API_HOST = "https://scf.qihuian.com/service";
    window.IMAGE_HOST = "https://scf.qihuian.com";
    window.JDH_HOST = "https://qihuian.jdh.com.cn";
    window.PAY_HOST = 'https://pay.qihuian.com/admin/payCenter/error?result='
}
const app = dva({
  history: history,
  onError (e) {
    message.error(e.message);
  },
  onStateChange (state) {
    // console.log("dva里面的值："+ JSON.stringify(state))
  }
  // onReducer(reducer){
  //     const persistConfig = {
  //         key: 'root',
  //         storage,
  //     };
  //     return persistReducer(persistConfig, reducer);
  // }
});
createModel(app);
app.router(router);
app.use(createLoading());
app.use({
  extraEnhancers: [
    persistEnhancer()
  ],
});
app.start("#root");